import { createContext, useContext, useEffect, ReactNode, useCallback } from "react";
import { useStateWithMounted } from "src/utils/useStateWithMounted";
import { EAccountType, ESubscriptionStatus, getIsAccountActive } from "amp";
import { UserAndAccountContext } from "./UserAndAccountContext";
import { fetchDataDynamic } from "src/hooks/useFetchDataDynamic";
import LoadingScreen from "src/components/LoadingScreen";
import { useIsFleetAdmin } from "src/hooks/useIsFleetAdmin";

export interface IFleetAccount {
	external_id__c: string;
	name: string;
}

interface IFleetAccountsContextValue {
	fleetAccountId: string | undefined;
	setFleetAccountId: (accountId: string | undefined) => void;
	fleetAccounts: IFleetAccount[];
}

export const FleetAccountsContext = createContext<IFleetAccountsContextValue>({
	setFleetAccountId: () => undefined,
	fleetAccountId: undefined,
	fleetAccounts: undefined,
});

interface IFleetAccountsProviderProps {
	children: ReactNode;
}

export const FleetAccountsProvider = ({ children }: IFleetAccountsProviderProps) => {
	const { 
		setAccountId: setAccountIdHeader, 
		userId, 
		accountId: accountIdHeader 
	} = useContext(UserAndAccountContext);

	const isFleetAdmin = useIsFleetAdmin();
	
	const [fleetAccountId, setFleetAccountId] = useStateWithMounted<string | undefined>(undefined);
	const [fleetAccounts, setFleetAccounts] = useStateWithMounted<IFleetAccount[]>([]);

	const getAccountId = useCallback(async () => {
		const response = await fetchDataDynamic("account", {
			columns: ["external_id__c", "primary_account_holder__r__external_id__c", "stripe_subscription_status__c", "name"],
			condition: {
				conditions: [{
					conditions: [{
						left: { column: "account_type__c" },
						operator: "EQ",
						right: EAccountType.Fleet,
					},
					{
						left: { column: "account_type__c" },
						operator: "EQ",
						right: EAccountType.FleetPayPerWash,
					}],
					operator: "OR",
				}, {
					left: { column: "is_deleted" },
						operator: "EQ",
						right: false,
				}],
				operator: "AND",
			},
			joins: [{
				type: "INNER",
				relation: "mobileUserAccounts",
				condition: {
					left: { column: "mobile_user_id", alias: "root_mobileUserAccounts" },
					operator: "EQ",
					right: userId,
				}
			}],
		});

		if (response?.length) {
			let fleetAccountId = response.find(
				({
					primary_account_holder__r__external_id__c,
					stripe_subscription_status__c,
				}) =>
					primary_account_holder__r__external_id__c === userId &&
					getIsAccountActive(
						(stripe_subscription_status__c as unknown) as ESubscriptionStatus
					)
			)?.external_id__c;
			if (!fleetAccountId) {
				fleetAccountId = response[0].external_id__c;
			}
			setAccountIdHeader(fleetAccountId);
			setFleetAccountId(fleetAccountId);
			setFleetAccounts(response.map(({ external_id__c, name }) => ({ external_id__c, name })));
		} else {
			throw new Error("No fleet account found");
		}
	}, [setAccountIdHeader, userId]);

	const _setFleetAccountId = useCallback((accountId: string | undefined) => {
		setFleetAccountId(accountId);
		setAccountIdHeader(accountId);
	}, []);

	useEffect(() => {
		if (userId) {
			getAccountId();
		}
	}, [userId]);

	if (isFleetAdmin && (!fleetAccountId || !accountIdHeader)) {
		return <LoadingScreen />;
	}

	return (
		<FleetAccountsContext.Provider
			value={{
				fleetAccountId,
				setFleetAccountId: _setFleetAccountId,
				fleetAccounts,
			}}
		>
			{children}
		</FleetAccountsContext.Provider>
	);
};